import React, { FunctionComponent } from "react";
import "./styles.scss";
import Section from "../../Section";

interface BluePrintFeaturedTalentInterface {
  palleteColorList?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
}

const BluePrintFeaturedTalent: FunctionComponent<BluePrintFeaturedTalentInterface> = props => {
  const { palleteColorList } = props;
  return <Section sectionClassName="c-bluePrint-featured" theme={palleteColorList?.bgColor.title}></Section>;
};

export default BluePrintFeaturedTalent;
